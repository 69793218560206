<template>
    <b-card>
        Invoice List
    </b-card>
</template>

<script>

export default {
    name: 'purchase-merchant-list',
    data: function () {
        return {
        }
    }
}
</script>